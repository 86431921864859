
import { API_URL, Live_API_URL, Live_URL,isProduction } from "../apiConfge";



export const BASE_URL = isProduction ? Live_API_URL : API_URL;
export const END_POINT = {
  Faq: "/v1/faq",
  Allploicy: "/v1/policy/all",
}
