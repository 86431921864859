import React, { useEffect, useState, useRef } from 'react';
import { ImgProvider } from '../Assest/ImgProvider';
import { Link, useLocation } from 'react-router-dom';
import { App_URL, Plan_URL, Live_App_URL, Live_Plan_URL,isProduction } from '../apiConfge';

export default function Header() {
  const location = useLocation();
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);
  const [activeMenu, setActiveMenu] = useState('home');
  useEffect(() => {
    const id = location?.hash;
    const idname = id.replace("#", "");
    scrollTo(idname);
  }, [location]);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleMenuClick = (id) => {
    setActiveMenu(id); // Update active menu item
    scrollTo(id);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='hederbox'>
      <div className="max-w-7xl " style={{ margin: "auto" }}>
        <nav className="meuuuu" ref={menuRef} >
          <div className=' grid gap-3    md:grid-cols-12' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="navbar-brand col-span-1">
              <Link to="/" onClick={() => scrollTo('home')}>
                <img src={ImgProvider.logo} alt='logo' height={40} width={150} />
              </Link>
            </div>
            <div className='col-span-7 melist '>
              <div className={`menu ${isOpen ? "open" : ""}`}>
                <Link to="/#about" onClick={() => handleMenuClick('about')}>
                  <div className={`menutext relative transition-all duration-200 ease-in-out hover:text-[#039EA2] hover:scale-105 ${activeMenu === 'about' ? '!text-[#039EA2]' : ''}`} >
                    Why Choose Us
                    {activeMenu === 'about' && (<span className='underlinetex' />)}
                  </div>
                </Link>
                <Link to="/#services" onClick={() => handleMenuClick('services')}>
                  <div className={`menutext transition-all relative duration-200 ease-in-out hover:text-[#039EA2] hover:scale-105 ${activeMenu === 'services' ? ' !text-[#039EA2]' : ''}`}>
                    Our Services
                    {activeMenu === 'services' && (<span className='underlinetex' />)}
                  </div>
                </Link>
                <Link to="/#app" onClick={() => handleMenuClick('app')}>
                  <div className={`menutext transition-all relative duration-200 ease-in-out hover:text-[#039EA2] hover:scale-105 ${activeMenu === 'app' ? ' !text-[#039EA2] ' : ''}`} >
                    Our App
                    {activeMenu === 'app' && (<span className='underlinetex' />)}
                  </div>
                </Link>
                <Link to="/contact" onClick={() => setActiveLink('/contact')}>
                  <div className={`menutext transition-all relative duration-200 ease-in-out hover:text-[#039EA2] hover:scale-105 ${activeLink === "/contact" ? '  !text-[#039EA2]  ' : ''}`}>
                    Contact Us
                    {activeLink === '/contact' && (<span className='underlinetex' />)}
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-span-4 melist'>
              <div className="space1">
                <Link to={isProduction ? Live_App_URL : App_URL}>
              
                  <div className='textallm' style={{ color: "#039EA2" }}>Already a member</div>
                </Link>
              </div>
              <div className="space1">
                <Link to={isProduction ? Live_Plan_URL : Plan_URL}>
                  <div className='btnlay'>Register <img src={ImgProvider?.rightarrow} /></div>
                </Link>
              </div>
            </div>
          </div>
          <div className="menu-toggle" onClick={toggleMenu}> ☰
            <div>
              <Link smooth={true} to={isProduction  ? Live_App_URL : App_URL}>
                <div className='textallm' style={{ color: "#039EA2" }}>Already a member</div>
              </Link>
              <Link to={isProduction  ? Live_Plan_URL : Plan_URL}>
                <div className='btnlay'>Register <img src={ImgProvider?.rightarrow} /></div>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
