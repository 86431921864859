import React, { useState } from "react";
import { ImgProvider } from "../Assest/ImgProvider";

export default function Mailer() {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    // const navigate = useNavigate();s
    const handleChange = () => {
        setOpen(true);
    };
    const handleSubmit = async () => { };
    return (
        <div className="maainthank max-w-3xl">
            <div style={{ paddingTop: "20px" }} />
            <img loading="lazy" src={ImgProvider.Whitlogo} alt="endimg" height={40} width={150} />
            <div style={{ paddingTop: "20px" }} />
            <div className="thaaa">
                    <img src={ImgProvider?.thankyoumobile} alt="yoganame" className="phone-mockup " />
                <div className="card">
                    <div className="thanktext" > Thank You! </div>
                    <div className="upgradthank" style={{ marginTop: "10px", marginBottom: "10px" }} > "Thank you for joining our yoga community!” </div>
                    <div className="upgradthank"> 
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                        Get ready for a transformative journey to wellness and inner balance..
                    </div>
                    <div style={{ paddingTop: "20px" }} />
                    <div className="btnlay1">  Get start </div>
                </div>
            </div>
        </div>
    );
}
