import React, { useState } from 'react'
import { ImgProvider } from '../../Assest/ImgProvider'
import { Link } from 'react-router-dom'
import { Plan_URL ,Live_Plan_URL,isProduction} from '../../apiConfge'


export default function BannerSection() {

  return (
    <div id="home" className='bannersize'>
      <div className='max-w-7xl px-2 mx-auto'>
        <div className="  grid md:grid-cols-12">
          <div className="md:col-span-6 sm:col-span-12">
            <div className="flex sm:mt-14 mt-20 mb-5 text-3xl subhedting1 " >Discover Inner Peace and Physical Strength</div>
            <div className="flex items-center mt-10 textfontll">
              Discover the power of yoga with personalized sessions and progress tracking, no matter your experience level.
            </div>
          </div>
        </div>
        {/* {console.log("mainpage",Live_Plan_URL)}
        {console.log("mainpage222",isProduction)} */}
        <div className="grid md:grid-cols-12  sm:grid-cols-12">
          <div className="sm:col-span-0 md:col-span-3">
            <div className="banstyl">
              <img src={ImgProvider?.banneryoga1} alt='banneryoga' style={{ height: "50%", width: "40%" }} />
              <div className=' mt-5 mb-20 '>
                <Link smooth={true} to={isProduction ? Live_Plan_URL : Plan_URL}>
                  <div className="bannerbutton"> Start Your Flow </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-9 ">
            <img src={ImgProvider?.banneryoga} alt='banneryoga' style={{ width: "100%", height: "auto" }} />
            <div className=' mt-2 bbbb '>
              <Link smooth={true} to={isProduction ? Live_Plan_URL : Plan_URL}>
                <div className="bannerbutton"> Start Your Flow </div>

              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
