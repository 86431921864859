import { React, useEffect, useState } from 'react'
import { ImgProvider } from '../Assest/ImgProvider'
import { Link, useLocation } from 'react-router-dom';
export default function Footer1() {
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);
  useEffect(() => {
    setActiveLink(pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  const footerLinks = [
    { path: '/about', label: 'About Us' },
    { path: '/services', label: 'Our Services' },
    { path: '/cookiepolicy', label: 'Cookie Policy' },
    { path: '/privacypolicy', label: 'Privacy Policy' },
    { path: '/termscondition', label: 'Terms & Condition' },
    // { path: '/faq', label: 'FAQs' },
    // { path: '/contact', label: 'Contact Us' },
  ];
  return (
    <div className='footer1box '>
      <div className="max-w-7xl px-2 py-3 mx-auto tracking-wide mt-10 mb-5 md:px-4 md:mt-5 md:mb-5">
        <div className="flexwebfoot grid  py-8 text-lg leading-6 text-gray-800  md:grid-cols-7 sm:grid-cols-1">
          {footerLinks.map((link, index) => (
            <div className={`space-y-3 ${index > 0 ? 'lg:border-l-2 lg:border-white' : ''}`}  key={index}>
              <Link to={link.path}>
                <div className={`mfooter transition-all relative duration-200 ease-in-out hover:text-[#039EA2] hover:scale-105  ${activeLink === link?.path ? ' !text-[#039EA2]  ' : ''}`}>
                  {link.label}
                  {activeLink === link?.path && (<span className='underlinetex1' />)}
                  </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className='menulay1 mb-5'>
        <Link to={"https://www.facebook.com/61573237086657"}><div className='soiliconbox'><img src={ImgProvider?.facebook} alt="facebook" width={15} height={15} /></div></Link>
        <Link to={"https://www.instagram.com/yoga_.rise/"}><div className='soiliconbox'><img src={ImgProvider?.instagram} alt="instgarma" width={15} height={15} /></div></Link>
        <Link to={"https://in.pinterest.com/yoga_rise/"}><div className='soiliconbox'><img src={ImgProvider?.pinterest} alt="pinterest" width={15} height={15} /></div></Link>
        <Link to={"https://www.youtube.com/@OfficialYogaRise"}><div className='soiliconbox'><img src={ImgProvider?.youtube} alt="youtube" width={15} height={15} /></div></Link>
      </div>
      <div className='coputext mb-5'> Copyright © {new Date().getFullYear()} YogaRise 
      </div>
    </div>
  )
}
