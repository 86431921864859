
import React from 'react';
import { RotatingLines, ColorRing } from 'react-loader-spinner'

const Loders = ({ isLoading }) => {
  return (
    <div open={isLoading} style={{ display: "flex", justifyContent: "center", alignItems: "center",background:"#000" }}   >
      <div  >
        <ColorRing
          visible={isLoading}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#000', '#000', '#000', '#000', '#000']}
        />
      </div>
    </div>
  );
};

export default Loders;