export const App_URL = process.env.REACT_APP_YOGARISE_APP_URL;
export const Plan_URL = process.env.REACT_APP_YOGARISE_PLAN_URL;
export const API_URL = process.env.REACT_APP_YOGARISE_API_URL;
export const Live_App_URL = process.env.REACT_APP_YOGARISE_LIVE_APP_URL;
export const Live_Plan_URL = process.env.REACT_APP_YOGARISE_LIVE_PLAN_URL;
export const Live_API_URL = process.env.REACT_APP_YOGARISE_LIVE_API_URL;
export const Live_URL = process.env.REACT_APP_YOGARISE_LIVE_URL;
export const isProduction = process.env.REACT_APP_YOGARISE_IS_PRODUCTION === "true";
// export const App_URL1 = process.env.YOGARISE_APP_URL1;
// export const Plan_URL1 = process.env.YOGARISE_PLAN_URL1;
// export const App_URL = 'https://app-uat.yogarise.com/';
// export const Plan_URL = 'https://plan-uat.yogarise.com/';