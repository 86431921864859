import React from 'react'
import { ImgProvider } from '../../Assest/ImgProvider'

export default function AboutSection() {
  return (
    <div id="about"className='aboutback'>
    <div className=" max-w-7xl mt-10 mb-10 px-3 mx-auto md:px-4 ">
      <div className=" grid gap-3   md:grid-cols-12">
        <div className="md:col-span-8 xs:col-span-12 flexweb1">
        <div className="  text-3xl px-3 subhedting1 ">Why Choose Us</div>
          <div className="relative transition-all duration-700  rounded-xl ">
            <div className="w-full  text-left cursor-pointer">
              <div className=" py-2 px-3 items-center justify-between textfontll">
              At our core, we offer a personalized and holistic approach to wellness, with expert instructors guiding you through every step of your journey. From tailored yoga and meditation routines to nutritional advice, we cater to your unique goals and lifestyle. Our app provides easy access to on-demand classes, live sessions, and progress tracking, all available anytime, anywhere. With a focus on building a supportive community, we are committed to helping you achieve lasting physical and mental well-being. Choose us for professional guidance, flexibility, and a transformative wellness experience.
              </div>
              <div className="grid grid-cols-12 gap-4">
                <div className="smallbox col-span-4"> 24.5k <span className="smallboxspantext"> User per month </span></div>
                <div className="smallbox col-span-4"> 10000+ <span className="smallboxspantext"> Yoga Videos </span></div>
                <div className="smallbox col-span-4"> 8000+ <span className="smallboxspantext"> Meditation Audios </span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:col-span-4 xs:col-span-12">
          <div className="relative transition-all duration-700  rounded-xl">
            <div  className=" p-4 text-right cursor-pointer">
              <div className="items-center justify-between">
              <img src={ImgProvider?.about}alt='about' className='imglll'/> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
